/*
This is where you write custom SASS
*/


section.association-section img {
    max-width: 280px !important;
    width: 24% !important;
}

@media screen and (max-width: 767px) {
    section.association-section img {
        max-width: 280px !important;
        width: 48% !important;
    }
}


img.groups {
    display: inline-block !important;
    width: auto !important;
    max-width: 49% !important;
}


header {
    .spacer {
        &:before {
            content: '';
            position: absolute;
            background: #707070;
            height: 25px;
            width: 1px;
            transform: translateX(-50%);
            left: 50%;
            top: -3px;
        }
    }
}

section.hero-section-interior {
    @include media-breakpoint-down(md) {
        div > img.groups {
            max-width: 48% !important;
        }
    }
    .gradient-col {
        @include media-breakpoint-down(lg) {
            &:before {
                content: none !important;
            }
        }
        &:before {

            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            z-index: 1;
            width: 45%;
            background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
        }
    }
    [style*="height: 500px"] {
        @include media-breakpoint-down(lg) {
            & {
                height: auto !important;
            }
        }
    }

}
section.hero-section {

    @media screen and (max-width: 1500px) {
        .display-1 {
            font-size: calc(1.25rem + 3.9vw);
        }
    }
    @include media-breakpoint-down(md) {
        div > img.groups {
            max-width: 48% !important;
        }
    }
    .gradient-col {
        @include media-breakpoint-down(lg) {
            &:before {
                top: 0 !important;
                right: 0 !important;
                left: 0 !important;
                bottom: auto !important;
                height: 30% !important; 
                background: linear-gradient( 360deg ,rgba(255,255,255,0) 0,#fff 100%) !important;
                width: 100% !important;
            }
        }
        &:before {

            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            z-index: 1;
            width: 45%;
            background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
        }
    }
    [style*="height: 700px"] {
        @include media-breakpoint-down(lg) {
            & {
                height: auto !important;
            }
        }
        @include media-breakpoint-down(md) {
            & {
                height: auto !important;
            }
            .gradient-col {
                height: 45vw !important;
                min-height: 250px !important;
            }
        }
    }
}

nav.navbar {
    ul#main-menu {
        .emergency-btn {
            &:hover {
                background: darken($red, 5%) !important;
                &:before {
                    background: transparent !important;
                }
            }

        }

        @media screen and (min-width: 992px) {
            > li {
                > a {
                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 50px;
                        height: 5px;
                        transition: .3s ease;
                        background: transparent;
                    }
                    &.current, &.highlighted, &:hover {
                        &:before {
                            background: $primary;

                        }
                    }
                }
            }
        }
    }
}

section.cta-section {
    .emergency-cta {
        .cta-wrap {
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
                border: 4px solid #F86868;
            }
        }
    }
    .cta-wrap {
        p {
            height: 110px;
            display: flex;
            line-height: 31px;
            justify-content: center;
            align-items: center;
            bottom: 0;
            transition: .3s ease;
            letter-spacing: 0.04em;
        }
        &:hover {
            > p {
                height: 120px;
            }
        }
    }
}

section.testimonials {
    .panel-image {
        @include media-breakpoint-down(md) {
            & {
                height: 55vw;
                min-height: 300px;
            }
        }
    }
}

section.services-section {
    .panel-image {
        @include media-breakpoint-down(md) {
            & {
                height: 55vw;
                min-height: 300px;
            }
        }
    }
    .border-bottom {
        border-bottom: 1px solid rgba(255,255,255,0.5) !important;
    }
}

section.contact-section {
    @include media-breakpoint-down(md) {
        .container {
            max-width: 100% !important;
        }
    }
    .panel-image {
        @include media-breakpoint-down(md) {
            & {
                height: 55vw;
                min-height: 300px;
            }
        }
    }
    .form-control {  
        border: 1px solid transparent !important;
    }
    .form-group {
        margin-top: 0px !important;
        margin-bottom: 10px !important;
    }
    [id*="btnSend"] {
        max-width: 178px;
    }
}

footer {
    span.spacer {
        &:before {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%,-50%,0);
            width: 1px;
            height: 21px;
            background: rgba(255,255,255,0.50);
        }
    }
    a:not(.bg-CAE4C2) {
        color: white;
        &:hover {
            color: #d9d9d9!important;
        }
    }
}

body.interior {
    .form-wrap  {
        @include media-breakpoint-down(md) {
            & {
                max-width: none !important;
            }
            p, a, span {
                margin-left: 0 !important;
                margin-right: 0 !important;
            }
        }

    }
    
.customForm {
	background: #eaebe9;
	-webkit-box-shadow: 0px 0px 25px -7px rgba(0, 0, 0, 0.14);
	-moz-box-shadow: 0px 0px 25px -7px rgba(0, 0, 0, 0.14);
	box-shadow: 0px 0px 25px -7px rgba(0, 0, 0, 0.14);
}

.customForm p:first-of-type {
	color: #767775;
	font-size: 14px;
	margin: 20px 46px 20px 10px;
}

.customForm p {
	color: #353535;
	font-size: 14px;
	margin: 20px 46px 20px 10px;
}

.customForm .btn {
	width: 84%;
	margin: 20px 46px 30px 10px;
	padding: 12px 0;
	box-shadow: 1px 1px 0px rgba(255, 255, 255, .3), 2px 2px 0px rgba(0, 0, 0, .15);
	font-weight: 400;
	font-family: 'Poppins', sans-serif !important;
	font-size: 16px;
}

.customForm img {
	margin: auto;
	width: 326px;
}

.interior .customForm h2,
.formTitles {
	font-family: 'Poppins', sans-serif !important;
	font-size: 28px !important;
	font-weight: 300 !important;
	color: #4f4f4f !important;
	text-shadow: none !important;
	display: block;
	margin-bottom: 20px;
	margin-left: 10px !important;
}

.formTitles~.formTitles {
	margin: -5px;
}

.customForm .ph {
	font-size: 20px;
}

.interior .customForm #btnSend {
	margin-right: 34px !important;
	width: 308px !important;
	font-size: 20px;
	padding: 10px 0;
	box-shadow: 1px 1px 0px rgba(255, 255, 255, .3), 2px 2px 0px rgba(0, 0, 0, .15) !important;
}

.interior .customForm ::placeholder {
	font-weight: 300 !important;
}

.fa-star {
	color: #ffd200;
	font-size: 28px;
	margin-left: 10px;
}

.box .inner-container h2 {
	display: none;
}

.box .inner-container a {
	position: relative;
	right: 0px;
	top: 65px;
}

.box .inner-container .page-item {
	padding: 0;
}

.box .inner-container blockquote {
	font-size: 22px;
	font-style: italic;
	padding: 0;
	color: #fff;
	font-weight: 100;
	font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
}

.box .inner-container .source {
	font-size: 18px;
	font-weight: 500;
	color: #fff;
}

.testimBox a.viewmore {
	border: 0;
	background: #779c49;
	color: #fff;
	box-shadow: 2px 2px 0px #91b466, 3px 3px 0px #7e9d59;
	font-size: 16px;
	font-weight: 500;
}
}