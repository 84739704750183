.opacity-100 {
opacity:100 !important;
}

.py-30px {
padding-top:30px !important;padding-bottom:30px !important;
}

@media screen and (min-width: 1200px) {.py-xl-50px {
padding-top:50px !important;padding-bottom:50px !important;
}

}
 .m-0 {
margin:0px !important;
}

.font-size-21px {
font-size:21px !important;
}

.mx-15px {
margin-left:15px !important;margin-right:15px !important;
}

.font-size-21px {
font-size:21px !important;
}

.bg-700 {
color:700px !important;
}

.p-0 {
padding:0px !important;
}

.py-10px {
padding-top:10px !important;padding-bottom:10px !important;
}

.w-100 {
width:100% !important;
}

.bg-700 {
color:700px !important;
}

.font-size-14 {
font-size:14px !important;
}

.m-0 {
margin:0px !important;
}

.ml-20px {
margin-left:20px !important;
}

.m-0 {
margin:0px !important;
}

.ml-20px {
margin-left:20px !important;
}

.bg-700 {
color:700px !important;
}

.h-100 {
height:100% !important;
}

.w-100 {
width:100% !important;
}

.m-0 {
margin:0px !important;
}

.pb-100px {
padding-bottom:100px !important;
}

@media screen and (min-width: 992px) {.py-lg-0 {
padding-top:0px !important;padding-bottom:0px !important;
}

}
 .w-100 {
width:100% !important;
}

.p-0 {
padding:0px !important;
}

.p-20px {
padding:20px !important;
}

.mx-2 {
margin-left:2px !important;margin-right:2px !important;
}

.h-100 {
height:100% !important;
}

.py-35px {
padding-top:35px !important;padding-bottom:35px !important;
}

.font-weight-400 {
font-weight:400 !important;
}

.m-0 {
margin:0px !important;
}

.py-80px {
padding-top:80px !important;padding-bottom:80px !important;
}

.px-10px {
padding-left:10px !important;padding-right:10px !important;
}

@media screen and (min-width: 992px) {.pb-lg-0 {
padding-bottom:0px !important;
}

}
 .pb-20px {
padding-bottom:20px !important;
}

.pb-355px {
padding-bottom:355px !important;
}

.px-20px {
padding-left:20px !important;padding-right:20px !important;
}

.m-0 {
margin:0px !important;
}

.font-size-21px {
font-size:21px !important;
}

.w-100 {
width:100% !important;
}

.px-10px {
padding-left:10px !important;padding-right:10px !important;
}

@media screen and (min-width: 992px) {.pb-lg-0 {
padding-bottom:0px !important;
}

}
 .pb-20px {
padding-bottom:20px !important;
}

.px-10px {
padding-left:10px !important;padding-right:10px !important;
}

.pt-50px {
padding-top:50px !important;
}

.pb-100px {
padding-bottom:100px !important;
}

@media screen and (min-width: 992px) {.pt-lg-100px {
padding-top:100px !important;
}

}
 .mb-40px {
margin-bottom:40px !important;
}

.my-10px {
margin-top:10px !important;margin-bottom:10px !important;
}

.py-0 {
padding-top:0px !important;padding-bottom:0px !important;
}

@media screen and (min-width: 768px) {.mx-md-0 {
margin-left:0px !important;margin-right:0px !important;
}

}
 .w-100 {
width:100% !important;
}

.px-0 {
padding-left:0px !important;padding-right:0px !important;
}

.p-10px {
padding:10px !important;
}

.m-0 {
margin:0px !important;
}

.pl-30px {
padding-left:30px !important;
}

$theme-colors: map-merge(("707070":#707070), $theme-colors);
 .font-weight-400 {
font-weight:400 !important;
}

.font-size-16px {
font-size:16px !important;
}

.line-height-28px {
line-height:28px !important;
}

.font-weight-300 {
font-weight:300 !important;
}

.my-10px {
margin-top:10px !important;margin-bottom:10px !important;
}

.mt-20px {
margin-top:20px !important;
}

.px-0 {
padding-left:0px !important;padding-right:0px !important;
}

@media screen and (min-width: 576px) {.mb-sm-0 {
margin-bottom:0px !important;
}

}
 .mb-10px {
margin-bottom:10px !important;
}

.h-100 {
height:100% !important;
}

.h-100 {
height:100% !important;
}

.px-0 {
padding-left:0px !important;padding-right:0px !important;
}

.h-100 {
height:100% !important;
}

.py-100px {
padding-top:100px !important;padding-bottom:100px !important;
}

.pb-25px {
padding-bottom:25px !important;
}

.font-weight-300 {
font-weight:300 !important;
}

.font-weight-300 {
font-weight:300 !important;
}

.line-height-25px {
line-height:25px !important;
}

$theme-colors: map-merge(("707070":#707070), $theme-colors);
 .font-size-16px {
font-size:16px !important;
}

.font-weight-400 {
font-weight:400 !important;
}

.mt-40px {
margin-top:40px !important;
}

.px-0 {
padding-left:0px !important;padding-right:0px !important;
}

.w-100 {
width:100% !important;
}

.py-15px {
padding-top:15px !important;padding-bottom:15px !important;
}

.mx-5px {
margin-left:5px !important;margin-right:5px !important;
}

.mt-10px {
margin-top:10px !important;
}

.h-100 {
height:100% !important;
}

.py-100px {
padding-top:100px !important;padding-bottom:100px !important;
}

@media screen and (min-width: 992px) {.py-lg-130px {
padding-top:130px !important;padding-bottom:130px !important;
}

}
 .pb-25px {
padding-bottom:25px !important;
}

.font-weight-300 {
font-weight:300 !important;
}

.py-15px {
padding-top:15px !important;padding-bottom:15px !important;
}

.font-size-18px {
font-size:18px !important;
}

.mr-10px {
margin-right:10px !important;
}

.mr-10px {
margin-right:10px !important;
}

.m-0 {
margin:0px !important;
}

.pt-15px {
padding-top:15px !important;
}

.pb-5px {
padding-bottom:5px !important;
}

.font-weight-300 {
font-weight:300 !important;
}

.font-size-18px {
font-size:18px !important;
}

.mt-50px {
margin-top:50px !important;
}

.w-100 {
width:100% !important;
}

.px-0 {
padding-left:0px !important;padding-right:0px !important;
}

.py-12px {
padding-top:12px !important;padding-bottom:12px !important;
}

@media screen and (min-width: 992px) {.py-lg-140px {
padding-top:140px !important;padding-bottom:140px !important;
}

}
 @media screen and (min-width: 992px) {.py-lg-0 {
padding-top:0px !important;padding-bottom:0px !important;
}

}
 .py-100px {
padding-top:100px !important;padding-bottom:100px !important;
}

.pb-25px {
padding-bottom:25px !important;
}

.font-weight-300 {
font-weight:300 !important;
}

.w-100 {
width:100% !important;
}

.py-12px {
padding-top:12px !important;padding-bottom:12px !important;
}

.px-0 {
padding-left:0px !important;padding-right:0px !important;
}

@media screen and (min-width: 992px) {.py-lg-90px {
padding-top:90px !important;padding-bottom:90px !important;
}

}
 .py-70px {
padding-top:70px !important;padding-bottom:70px !important;
}

@media screen and (min-width: 992px) {.px-lg-0 {
padding-left:0px !important;padding-right:0px !important;
}

}
 .pb-10px {
padding-bottom:10px !important;
}

.p-5px {
padding:5px !important;
}

.py-75px {
padding-top:75px !important;padding-bottom:75px !important;
}

.py-10px {
padding-top:10px !important;padding-bottom:10px !important;
}

.py-10px {
padding-top:10px !important;padding-bottom:10px !important;
}

.m-0 {
margin:0px !important;
}

.font-size-18px {
font-size:18px !important;
}

.m-0 {
margin:0px !important;
}

.mx-35px {
margin-left:35px !important;margin-right:35px !important;
}

@media screen and (min-width: 768px) {.mt-md-0 {
margin-top:0px !important;
}

}
 .mt-10px {
margin-top:10px !important;
}

.mx-35px {
margin-left:35px !important;margin-right:35px !important;
}

@media screen and (min-width: 992px) {.mt-lg-0 {
margin-top:0px !important;
}

}
 .mt-10px {
margin-top:10px !important;
}

.mx-35px {
margin-left:35px !important;margin-right:35px !important;
}

@media screen and (min-width: 1200px) {.mt-xl-0 {
margin-top:0px !important;
}

}
 .mt-10px {
margin-top:10px !important;
}

$theme-colors: map-merge(("CAE4C2":#CAE4C2), $theme-colors);
 .font-size-18px {
font-size:18px !important;
}

.mr-10px {
margin-right:10px !important;
}

.opacity-75 {
opacity:75 !important;
}

.font-size-14px {
font-size:14px !important;
}

.mt-10px {
margin-top:10px !important;
}

.m-0 {
margin:0px !important;
}

.mx-15px {
margin-left:15px !important;margin-right:15px !important;
}

.ml-4 {
margin-left:4px !important;
}

.mb-25px {
margin-bottom:25px !important;
}

.p-0 {
padding:0px !important;
}

